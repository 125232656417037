import React, { useState } from "react";
import { useForm, ValidationError } from "@formspree/react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import freeEmailDomains from "free-email-domains";

function SeoAuditForm({ webDesignEastbourne, extraButton }) {
	const [state, handleSubmit] = useForm("mknalbrg");
	const [emailError, setEmailError] = useState(null);

	const validateEmail = (email) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (!emailRegex.test(email)) {
			return "Please provide a valid email address.";
		}
		const domain = email.split("@")[1];
		if (freeEmailDomains.includes(domain)) {
			return "Please provide a business email.";
		}
		return null;
	};

	const handleEmailChange = (event) => {
		const email = event.target.value;
		const error = validateEmail(email);
		setEmailError(error);
	};

	const handleFormSubmit = async (event) => {
		event.preventDefault();
		const emailInput = event.target.elements["_replyto"].value;
		const emailValidationError = validateEmail(emailInput);
		if (emailValidationError) {
			setEmailError(emailValidationError);
			return;
		}
		setEmailError(null);
		await handleSubmit(event);
	};

	if (state.succeeded) {
		window.location = "/thank-you";
		return null;
	}

	return (
		<Form className="w-100" onSubmit={handleFormSubmit}>
			<Form.Group className="mb-4" controlId="full-name">
				<Form.Control
					placeholder="Name"
					className="py-3"
					name="full-name"
					type="text"
				/>
			</Form.Group>
			<ValidationError prefix="Name" field="full-name" errors={state.errors} />

			<Form.Group className="mb-4" controlId="email">
				<Form.Control
					placeholder="Email Address"
					className="py-3"
					name="_replyto"
					type="email"
					onChange={handleEmailChange}
				/>
				{emailError && (
					<div>
						<p style={{ fontSize: "90%" }} className="text-danger text-start">
							{emailError}
						</p>
					</div>
				)}
			</Form.Group>
			{/* Remove default ValidationError for email as we handle it */}
			{/* <ValidationError prefix="Email" field="email" errors={state.errors} /> */}

			<Form.Group className="mb-4" controlId="phone">
				<Form.Control
					placeholder="Phone Number"
					className="py-3"
					name="phone"
					type="tel"
				/>
			</Form.Group>
			<ValidationError prefix="Phone" field="phone" errors={state.errors} />

			<Form.Group className="mb-4" controlId="company-name">
				<Form.Control
					placeholder="Company Name"
					className="py-3"
					name="company-name"
					type="text"
				/>
			</Form.Group>
			<ValidationError
				prefix="Company Name"
				field="company-name"
				errors={state.errors}
			/>

			<Form.Group className="mb-4" controlId="website-url">
				<Form.Control
					placeholder="Website URL"
					className="py-3"
					name="website-url"
					type="text"
				/>
			</Form.Group>
			<ValidationError
				prefix="Website URL"
				field="website-url"
				errors={state.errors}
			/>

			{/* <Form.Group className="mb-4" controlId="annualTurnover">
				<Form.Control
					placeholder="Annual Turnover"
					className="py-3"
					name="annualTurnover"
					type="text"
				/>
			</Form.Group>
			<ValidationError
				prefix="Annual Turnover"
				field="annualTurnover"
				errors={state.errors}
			/> */}

			{/* <Form.Group className="mb-4" controlId="noOfEmployees">
				<Form.Control
					placeholder="No of Employees"
					className="py-3"
					name="noOfEmployees"
					type="text"
				/>
			</Form.Group>
			<ValidationError
				prefix="No of Employees"
				field="noOfEmployees"
				errors={state.errors}
			/> */}

			{/* <Form.Group
				className=" mb-4 text-start"
				controlId="definedMarketingBudget"
			>
				<Form.Check
					type="checkbox"
					name="definedMarketingBudget"
					label="Defined Marketing Budget"
				/>
			</Form.Group>
			<ValidationError
				prefix="Defined Marketing Budget"
				field="definedMarketingBudget"
				errors={state.errors}
			/> */}

			{/* <Form.Group className="mb-4" controlId="averageOrderValue">
				<Form.Control
					placeholder="Average Order Value"
					className="py-3"
					name="averageOrderValue"
					type="text"
				/>
			</Form.Group>
			<ValidationError
				prefix="Average Order Value"
				field="averageOrderValue"
				errors={state.errors}
			/> */}

			<div className="text-start">
				<Button
					variant="light-blue"
					style={{ borderRadius: "0.35rem" }}
					className="btn western w-100 w-md-auto  white-link-yellow px-5 mt-4 mt-md-0 me-md-5 py-2 "
					type="submit"
					id="contact-send-btn"
					disabled={state.submitting}
				>
					SUBMIT
				</Button>
				{extraButton && (
					<Button
						variant="white"
						style={{ borderRadius: "0.35rem" }}
						className="btn western w-100 w-md-auto ms-md-auto  primary-link px-xl-5 px-3 mt-4 mt-md-0  py-2 "
						href="#book-now"
						id="contact-send-btn"
						disabled={state.submitting}
					>
						Talk to an expert
					</Button>
				)}
			</div>
		</Form>
	);
}

export default SeoAuditForm;
